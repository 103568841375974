import { booking } from '@/api/booking'
import 'devextreme/dist/css/dx.light.css'
import { Workbook } from 'exceljs'
import { exportDataGrid } from 'devextreme/excel_exporter'
import { saveAs } from 'file-saver-es'
import { convertUTC } from '@/@core/utils/utils'
import {
  DxDataGrid,
  DxColumn,
  DxEditing,
  DxFilterRow,
  DxHeaderFilter,
  DxGroupPanel,
  DxGrouping,
  DxScrolling,
  DxPaging,
  DxPager,
  DxSummary,
  DxLookup,
  DxTotalItem,
  DxGroupItem,
  DxMasterDetail,
  DxStringLengthRule,
  DxRequiredRule,
  DxRangeRule,
  DxValueFormat,
  DxColumnChooser,
  DxColumnFixing,
  DxSelection,
  DxExport,
} from 'devextreme-vue/data-grid'
const dataGridRef = 'tablePackage'
export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxEditing,
    DxFilterRow,
    DxHeaderFilter,
    DxGroupPanel,
    DxGrouping,
    DxScrolling,
    DxPaging,
    DxPager,
    DxSummary,
    DxLookup,
    DxTotalItem,
    DxGroupItem,
    DxMasterDetail,
    DxStringLengthRule,
    DxRequiredRule,
    DxRangeRule,
    DxValueFormat,
    DxColumnChooser,
    DxColumnFixing,
    DxSelection,
    DxExport
  },
  data() {
    return {
      listAction: [],
      listBDC: [],
      listKeyCodeAction: [],
      ListBDCRevert: [],
      currentPage: 1,
      perPage: 50,
      dataGridRef: 'tablePackage',
      optionPerPage: [10, 50, 100],
      configTable: [
        {
          caption: 'BDC',
          field: 'Bdc',
          isShow: true,
          alignment: 'center',
          width: '15%',
        },
        {
          caption: 'Action',
          field: 'Action',
          isShow: true,
          alignment: 'center',
          cellTemplate: 'Action',
          width: '20%',
        },
        {
          caption: 'Người tạo',
          field: 'CreateByName',
          isShow: true,
          alignment: 'center',
          width: '15%',
        },
        {
          caption: 'Ngày tạo',
          field: 'CreateDate',
          cellTemplate: 'CreateDate',
          isShow: true,
          alignment: 'center',
          width: '15%',
        },
        {
          caption: 'Người thay đổi',
          field: 'UpdateByName',
          isShow: true,
          alignment: 'center',
          width: '15%',
        },
        {
          caption: 'Ngày thay đổi',
          field: 'UpdateDate',
          cellTemplate: 'UpdateDate',
          isShow: true,
          alignment: 'center',
          width: '15%',
        },
        {
          caption: '',
          field: 'action',
          cellTemplate: 'actionTable',
          isShow: true,
          alignment: 'center',
          width: '5%',
        }

      ],
      search: {
        FromDate: this.getUTC('start', 'day'),
        ToDate: this.getUTC('start', 'day'),
        Action: [],
        BDC: [],
        CashierCode: null
      },
      listSale: null,
      windowHeight: 0,
      heightTable: 400,
      isLoading: false
    }
  },
  created() {
    this.getConfig_RCOM01()
    this.getListCashier_RCS01()
    this.getListBDCRevert()
  },
  computed: {
    dataGrid() {
      return this.$refs[dataGridRef].instance
    },
  },
  watch: {
    windowHeight(value) {
      if (value > 600) {
        this.heightTable = value - 200
      }
    }
  },
  methods: {
    async getConfig_RCOM01() {
      this.listAction = []
      const responseBooking_Config = await booking.getConfigInfo({ KeyGroup: 'BOOKING_CONFIG' })
      const listActionConfig = JSON.parse(responseBooking_Config.Data.find(x => x.KeyCode === 'BOOKING_REVERT_BDC_ACTION').KeyValue)
      const responseTransaction_Config = await booking.getConfigInfo({ KeyGroup: 'TRANSACTION_CONFIG' })
      if (responseTransaction_Config.Status === '200') {
        responseTransaction_Config.Data.forEach(x => {
          if (listActionConfig.find(y => y === x.KeyCode)) {
            this.listAction.push(x)
          }
        });
      }
    },
    async addAction() {
      this.isLoading = true
      const body = {
        BookingDetailId: [],
        Action: this.listKeyCodeAction,
        BDC: this.listBDC,
      }
      const response = await booking.api_IBKR01(body)
      this.showResToast(response)
      if (response.Status === '200') {
        this.getListBDCRevert()
        this.listBDC = []
        this.listKeyCodeAction = []
      } else {
        this.isLoading = false
      }
    },
    async getListBDCRevert() {
      this.isLoading = true
      const response = await booking.api_RBKR01(this.search)
      this.isLoading = false
      if(response.Status === '200') {
        this.ListBDCRevert = response.Data.BookingDetailRevert
      } 
    },
    async deleteBDCRevert(data) {
      const body = {
        BookingDetailRevertId: [data.Id]
      }
      const response = await booking.api_DBKR01(body)
      this.showResToast(response)
      if(response.Status === '200') {
        this.getListBDCRevert()
      } else {
        this.isLoading = false
      }
    },
    async getListCashier_RCS01() {
      await booking.api_RCS01().then(response => {
        if (response.Status === '200') {
          this.listSale = response?.Data?.Cashier.map(x => ({
            id: x.Id,
            label: x.CashierName,
          }))
        }
      })
    },
    exportExcel() {
      const workbook = new Workbook()
      const worksheet = workbook.addWorksheet('Booking')
      const timeZone = localStorage.getItem('timeZone')
      const listActionTemp = this.listAction
      exportDataGrid({
        component: this.dataGrid,
        worksheet,
        autoFilterEnabled: true,
        PrintSelectedRowsOnly: true,
        topLeftCell: { row: 4, column: 1 },
        customizeCell: function (options) {
          const { gridCell, excelCell } = options;
          if (gridCell.rowType === 'data') {
            if (gridCell.column.dataField == 'CreateDate') {
              excelCell.value = convertUTC(gridCell.value, 'DD/MM/YYYY')
            }
            if (gridCell.column.dataField == 'UpdateDate') {
              excelCell.value = convertUTC(gridCell.value, 'DD/MM/YYYY')
            }
            if (gridCell.column.dataField == 'Action') {
              excelCell.value = listActionTemp.find(x => x.KeyCode == gridCell.value) ? listActionTemp.find(x => x.KeyCode == gridCell.value).KeyValue : ''
            }
          }
        }
      }).then(cellRange => {
        // header
        const headerRow = worksheet.getRow(2)
        headerRow.height = 30
        worksheet.mergeCells(2, 1, 2, 8)

        headerRow.getCell(1).value = `DANH SÁCH BDC (từ ${this.convertUTC(this.search.FromDate, 'DD/MM/YYYY')} đến ${this.convertUTC(this.search.ToDate, 'DD/MM/YYYY')})`
        headerRow.getCell(1).font = { name: 'Segoe UI Light', size: 22 }
        headerRow.getCell(1).alignment = { horizontal: 'center' }

        // footer
        const footerRowIndex = cellRange.to.row + 2
        const footerRow = worksheet.getRow(footerRowIndex)
        worksheet.mergeCells(footerRowIndex, 1, footerRowIndex, 8)
        footerRow.getCell(1).font = { color: { argb: 'BFBFBF' }, italic: true }
        footerRow.getCell(1).alignment = { horizontal: 'right' }
      }).then(() => {
        workbook.xlsx.writeBuffer().then(buffer => {
          saveAs(new Blob([buffer], { type: 'application/octet-stream' }), `BDCRevert${this.convertUTC(this.search.FromDate, 'DD/MM/YYYY')}-${this.convertUTC(this.search.ToDate, 'DD/MM/YYYY')}.xlsx`)
        })
      })
    }
  },
}